/* eslint-disable react/jsx-filename-extension */
import React, {Component} from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'Frontend/components/Button';
import PageLayout from 'Frontend/components/PageLayout';
import {PageConfig} from 'Server/models';

import {Icon404} from 'Frontend/assets/images/icons';
import {FONTS} from 'Frontend/utils/fonts';
import {clampCss, pxToVw} from 'Frontend/utils/helpers';
import {MEDIA} from 'Frontend/utils/mediaQueries';
import dbConnect from 'Server/utils/dbConnect';
import {populateFooterItems, populateMenuItems} from 'Server/utils/pagePopulations';

/**
 * NotFound Page class.
 *
 * @export
 * @class NotFound
 * @extends {Component}
 */
export default class NotFound extends Component {
    static propTypes = {
        footer: PropTypes.object,
        menu: PropTypes.object
    }

    static defaultProps = {
        footer: null,
        menu: null
    }

    /**
     * Gets the layout for this page.
     *
     * @static
     * @param {Object} router        The router object from nextjs.
     * @param {Object} pageProps     All page props given from server.
     * @param {Object} PageComponent The page component to render.
     *
     * @returns {JSX} The complete page.
     * @memberof NotFound
     */
    static getLayout(router, pageProps, PageComponent) {
        // eslint-disable-next-line no-param-reassign
        pageProps.page = {
            seoDescription: 'Not found page',
            seoKeywordList: ['404', 'notfound'],
            seoNoFollow: true,
            seoNoIndex: true,
            seoPageTitle: '404 Not found | Url does not exist'
        };
        // eslint-disable-next-line no-param-reassign
        pageProps.footer.partner = null;

        return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <PageLayout router={router} {...pageProps} isColumn>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <PageComponent {...pageProps} />
            </PageLayout>
        );
    }

    /**
     * Renders the Component.
     *
     * @returns {Component} React Component.
     * @memberof NotFound
     */
    render() {
        return (
            <ContentWrapper>
                <Backdrop>
                    <White />
                    <WhiteBeigeBorder />
                    <Beige />
                </Backdrop>
                <Icon404 />
                <Big>404</Big>
                <Text>Wir konnten die<br />Seite nicht finden</Text>
                <StyledButton link="/" text="Zur Startseite" />
            </ContentWrapper>
        );
    }
}

const ContentWrapper = styled.div`
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    position: relative;
`;

const Backdrop = styled.div`
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
`;

const White = styled.div`
    background: #ffffff;
    flex: 1 1 auto;
`;

const WhiteBeigeBorder = styled.div`
    background: linear-gradient(to top left, #FAF6F4 0%, #FAF6F4 49.5%, #fff 50%, #fff 100%);
    height: ${clampCss(32, 'm')};
    width: 100%;

    ${MEDIA.TABLET.UP} {
        height: ${clampCss(64, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        height: max(95px, ${pxToVw(95, 'd')});
    }
`;

const Beige = styled.div`
    background: #FAF6F4;
    flex: 1 1 auto;
`;

const Big = styled.h1`
    ${FONTS.SEMIBOLD}
    color: ${({theme}) => theme.colors.textColor};
    font-size: ${clampCss(92, 'm')};
    letter-spacing: ${clampCss(2.4, 'm')};
    line-height: ${clampCss(55.2, 'm')};

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(92, 't')};
        letter-spacing: ${clampCss(2.4, 't')};
        line-height: ${clampCss(55.2, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(92px, ${pxToVw(92, 'd')});
        letter-spacing: max(2.4px, ${pxToVw(2.4, 'd')});
        line-height: max(55.2px, ${pxToVw(55.2, 'd')});
    }
`;

const Text = styled.p`
    ${FONTS.SEMIBOLD}
    color: ${({theme}) => theme.colors.textColor};
    font-size: ${clampCss(32, 'm')};
    letter-spacing: ${clampCss(1.2, 'm')};
    line-height: ${clampCss(38.4, 'm')};
    margin: 0 0 ${clampCss(40, 'm')} 0;
    text-align: center;

    ${MEDIA.TABLET.UP} {
        font-size: ${clampCss(32, 't')};
        letter-spacing: ${clampCss(1.2, 't')};
        line-height: ${clampCss(38.4, 't')};
        margin: 0 0 ${clampCss(40, 't')} 0;
    }

    ${MEDIA.DESKTOP.UP} {
        font-size: max(32px, ${pxToVw(32, 'd')});
        letter-spacing: max(1.2px, ${pxToVw(1.2, 'd')});
        line-height: max(38.4px, ${pxToVw(38.4, 'd')});
        margin: 0 0 max(40px, ${pxToVw(40, 'd')}) 0;
    }
`;

const StyledButton = styled(Button)`
    margin-bottom: ${clampCss(40, 'm')};

    ${MEDIA.TABLET.UP} {
        margin-bottom: ${clampCss(40, 't')};
    }

    ${MEDIA.DESKTOP.UP} {
        margin-bottom: max(40px, ${pxToVw(40, 'd')});
    }
`;

/**
 * Gets static props needed for the actual page.
 *
 * @export
 * @param {Object} ctx    The context in which this function is called.
 * @param {Object} params The params given for this page.
 * @returns {Object} An props object or an redirect.
 */
export async function getStaticProps() {
    let menuJson = {};
    let footerJson = {};

    try {
        await dbConnect();
        const menu = await PageConfig.findOne({type: 'Menu'});
        const footer = await PageConfig.findOne({type: 'Footer'})
            .populate('partner')
            .populate('socialMedia.icon');

        await populateMenuItems(menu.mainMenu);
        await populateFooterItems(footer.copyright);

        menuJson = JSON.stringify(menu.toObject());
        footerJson = JSON.stringify(footer.toObject());
    } catch (e) {
        menuJson = JSON.stringify({});
        footerJson = JSON.stringify({});
    }

    return {
        props: {
            footer: JSON.parse(footerJson),
            menu: JSON.parse(menuJson)
        },
        revalidate: 2
    };
}